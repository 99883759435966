.reset-form {
  width: 100%;
}

.reset-header .header-content {
  width: 100%;
  padding-bottom: 5%;
}

.reset-title {
  margin-left: 10%;
  width: 100%;
}

.login-footer {
  align-items: flex-start !important;
  flex-direction: column;
  height: unset !important;
  padding: .5rem !important;
}

.login-footer-link {
  padding: .25rem !important;
}
